@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin: 0;
  padding: 0;
}

.gameBox {
  min-height: 100vh;
  background-image: url("../Assets/imgs/darkbg.webp");
  background-size: cover;
  background-position: bottom left;
}

.gameboxlogo {
  text-align: center;
  margin-bottom: 15px;
}

.gameBox .playbtn {
  text-align: center;
  margin-top: 10px;
}

.gameBox .main-content {
  min-height: 100vh;
}

.gameBox p.error {
  color: red;
  font-weight: 300;
  font-size: 14px;
  margin-top: 10px;
}

.gameBox .playbtn button {
  border-radius: 100px;
  text-transform: uppercase;
  padding: 13px 0px;
  width: 335px;
  text-align: center;
  background: linear-gradient(to right, #dc94fe, #00caff);
  color: #120f1e;
  font-weight: 700;
  font-size: 17px;
  margin: 10px 0px;
  cursor: pointer;
  transition: 0.3s linear;
  border: none;
}

.gameBox .playbtn button:hover {
  opacity: 0.7;
}

.gameBox .gameboxcontent {
  text-align: center;
}

.text p {
  margin: 20px 0px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 180%;
  margin: 20px auto;
  width: 100%;
  max-width: 75%;
  text-align: center;
}

.gameBox .tc {
  text-align: center;
}

.gameBox .tc a {
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 91.667% */
  text-decoration-line: underline;
  display: inline-block;
  margin-top: 0px;
  transition: 0.3s linear;
}

.gameBox .tc a:hover {
  opacity: 0.7;
}

.gameBox .border__box {
  text-align: center;
  position: relative;
}

.gameBox .gameinner__box {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.gameBox .gameboxlogo img {
  width: 100px;
  padding-top: 20px;
}

.gameBox .onedayfreetrail {
  text-align: center;
}

.gameBox .trial {
  width: 400px;
  margin: 15px auto;
  display: block;
}

.gameBox .priceImg {
  width: 335px;
  margin: 0 auto;
  display: block;
}

.bdrmobile {
  display: none;
  margin: 0 auto;
}

.bdrdesk {
  margin: 0 auto;
  display: block;
}

.mainImg {
  width: 270px;
}

.disable-btn {
  opacity: 0.4 !important;
  cursor: default !important;
}

@media (max-width: 767px) {
  .gameBox .gameinner__box {
    left: 0%;
    top: 20px;
    transform: unset;
  }

  .bdrmobile {
    display: block;
  }

  .bdrdesk {
    display: none;
  }

  .gameboxlogo img {
    width: 90px;
  }

  .gameBox .main-content {
    margin: 0 auto;
    max-width: 375px;
  }

  .gameBox .text p {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .gameBox .tc a {
    margin-top: 0px;
  }

  .gameBox .playbtn button {
    margin: 5px 0px;
    width: 70%;
    padding: 10px 0px;
  }

  .gameBox p.error {
    margin-top: 7px;
    font-size: 11px;
  }

  .gameBox .trial {
    width: 80%;
  }

  .gameBox .priceImg {
    width: 70%;
  }
}